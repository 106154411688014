import React, { useState,useEffect,Fragment } from "react"

import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

function PagePromo({ pagePromoGroup}){

const [showSingle,setShowSingle] = useState(false)

const updatePosts=(e)=>{

if(pagePromoGroup.length>1){
  setShowSingle(true)
}else{
  setShowSingle(false)
}
}
useEffect(() => {
  updatePosts()
}, []);



return (
  <section className="content very-light-blue-background pagePromo">

   <div className="eighty-spacer"></div>
   <div className="content-block">
      <div className="centered-content-container">
         <div className="sixty-spacer"></div>
      </div>
      {showSingle ?
      <div className="one-flex-container htb-difference-mon-slider two-up ">
      {pagePromoGroup.length
        ? pagePromoGroup.map((prod,index) => (
          <div key={index}>
            <a href={`/${prod.promoPage.uri}`}>
                   <div className="third-flex-container third-height-fix staggerFadeIn stag-visible">
                    <div className={`products-list-block ${index===0 ? 'navy-background' : 'white-background font-diff-color-fix'}`}>

                         <h2 className="savings-txt-colour">{prod.promoPage.title}</h2>

                         {prod.promoPage.dynamicContent.layouts && prod.promoPage.dynamicContent.layouts.map((description,index) => (
                           <Fragment key={index}>
                           {description.cardTextForListings ? (
                             <h3 key={index} dangerouslySetInnerHTML={{ __html: description.cardTextForListings}}/>
                              ) : null}
                              </Fragment>
                         ))}
                         <br/>
                         Earn up to
                         <br/>

                      {prod.promoPage.dynamicContent.layouts && prod.promoPage.dynamicContent.layouts.map((description,index) => (
                        <Fragment key={index}>
                        {description.cardCtaForListings ? (
                      <div className="absolute-bottom-link-holder" key={index}>{description.cardCtaForListings}</div>
                      ) : null}
                      </Fragment>
                      ))}

                          {prod.promoPage.dynamicContent.layouts && prod.promoPage.dynamicContent.layouts.map((description,index) => (
                            <Fragment key={index}>
                            {description.aerPercent ? (
                              <>

                         <span className="fixed-rate-savings-accounts" key={index}>{description.aerPercent}&#37;</span>
                         <br/>
                         AER
                         <br/>
                         </>
                        ) : null}
                       </Fragment>
                      ))}



                   </div></div>

</a>
                </div>
        ))
      : null}
</div>
:<div className="one-flex-container">
<div className="half-flex-container white-background two-up ">
{pagePromoGroup.length
  ? pagePromoGroup.map((prod,index) => (
    <div key={index}>
             <div className="other-accounts-holder">
                <div className="other-accounts-header-holder">

                   <h2>{prod.promoPage.title}</h2>
                </div>

                    {prod.promoPage.dynamicContent.layouts && prod.promoPage.dynamicContent.layouts.map((description,index) => (
                      <div className="other-accounts-stat-holder" key={index}>
                      {description.aerPercent ? (
                        <>

                   <span className="fixed-rate-savings-accounts" key={index}>{description.aerPercent}&#37;</span>

                   </>
                  ) : null}
                 </div>
                ))}

                   {prod.promoPage.dynamicContent.layouts && prod.promoPage.dynamicContent.layouts.map((description,index) => (
                     <Fragment key={index}>
                     {description.cardTextForListings ? (
                       <div key={index} className="other-account-text-holder other-mtg" dangerouslySetInnerHTML={{ __html: description.cardTextForListings}}/>
                        ) : null}
                        </Fragment>
                   ))}

                {prod.promoPage.dynamicContent.layouts && prod.promoPage.dynamicContent.layouts.map((description,index) => (
                  <Fragment key={index}>
                  {description.cardCtaForListings ? (
                <a href={`/${prod.promoPage.uri}`} key={index}><div className="absolute-bottom-link-holder">{description.cardCtaForListings}</div></a>
              ) : null}
              </Fragment>
                ))}

             </div>


          </div>
  ))
: null}
</div>
</div>}
</div>
       <div className="eighty-spacer"></div>
  </section>
 )
  }


export default PagePromo
